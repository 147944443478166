import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const Work = props => {
  const title = "About"
  return (
    <Layout>
      <Seo title={title} />
      <div className="content text-shadow sm:w-4/5">
        <p>
          After spending my first few years out of college working as an
          investment banker - uniformed in business suits and pumps, equipped
          with a briefcase and forever waiting to experience the supposed thrill
          of quarter-point fluctuations in interest rates - I felt as though I’d
          discovered nirvana when the first task assigned to me by a creative
          director when I began my new career in advertising production was to
          search for a cool piece of music for a new business pitch and
          transcribe the lyrics.{" "}
        </p>

        <p>
          Since that first day, I’ve been incredibly fortunate in my career,
          beginning with the generous senior creatives and producers I met years
          ago at Allen and Dorward in San Francisco who took me under their
          wings while I learned the basics of radio and television production,
          and continuing through to the moment I first read the fabulous scripts
          I’d been hired to produce at then Goodby Berlin and Silverstein to
          launch the agency’s “Got Milk” campaign.{" "}
        </p>

        <p>
          I worked at GSP in San Francisco for almost fifteen years, first as a
          producer and then executive producer on Milk, Discover Card, Budweiser
          and Comcast. After relocating to the Seattle area in 2006 with my
          husband and our two children, falling in love with our new Labrador
          retriever puppy and then a horse, I continued to freelance for GSP.{" "}
        </p>

        <p>
          When an opportunity arose to challenge myself with a different aspect
          of production, I joined Advertising Production Resources in 2008 as a
          consultant to manage its new relationship with Microsoft. Through that
          experience, I sharpened my skills in many of the business aspects of
          production, including globalization and integrating production across
          media. Additionally, I gained a unique insight into the world of
          advertising from the client’s perspective.{" "}
        </p>

        <p>
          After a little over one year at APR, I decided to return to the world
          of agency production, to the job that continually exposes me to the
          many smart, talented and truly interesting people who not only inspire
          me on a daily basis to grow creatively, technically and
          intellectually, but who also make me laugh.{" "}
        </p>

        <p>
          Someone asked me once if I had any kind of a basic philosophy when it
          comes to producing. In a nutshell, my answer was this:
        </p>

        <p>
          Be honest and be nice. Treat people with respect. Look for and focus
          on what’s good and enjoy all that you find there. Don’t worry about
          the crap that pops up along the way – just figure out the best way to
          deal with it and learn. And understand how truly lucky we are to be in
          this business – the whole thing is a pretty amazing ride.{" "}
        </p>

        <br />
        <p>
          <Link to="/about/what-people-say">What People Say →</Link>
        </p>
      </div>
    </Layout>
  )
}

export default Work
